.Card404 {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .Card404>h1{
      font-size: 4rem;
  }
  
  .Card404>p{
      font-size: 2rem;
  }
  
  .btn404{
      padding: 10px 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #5F5FEE;
      cursor: pointer;
      border-radius: 10px;
      border: none;
      outline: none;
      margin-top: 10px;
      color: white;
  }
  
  .btn404:hover{
      /* background: #6366F1; */
      opacity: 95%;
  }
  
  .btn404>span:first-child{
      padding-right: 5px;
  }